@import "common/theme";

.pages-header{
    padding: 60px 0;
    background: url(images/help_banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    h1{
        color: #fff;
        font-weight: 700;
        font-size: 50px;
    }
    .breadcrumbs{
        display: flex;
        flex-wrap: wrap;
        margin: 30px 0 0 0;
        padding: 0;
        li{
            list-style: none;
            & + li{
                margin-left: 25px;
                position: relative;
                &:before{
                    position: absolute;
                    content: "/";
                    left: -14px;
                    top: 0;
                }
            }
            a{
                color: #fff;
                span{
                    margin-right: 5px;
                }
                &:hover{
                    color: $dark-bg;
                }
            }
            &.active{
                color: #eee;
            }
        }
    }
}