//Primary Color
$pcolor: #F44336;

//Secondary Color
$scolor: #2196f3;

//Other Colors
$color1: #f9a825;
$color2: #23c75a;

//Background Color
$section-bg: #f5f5f5;
$dark-bg:#252B33;

//Text Color
$text-color: #504b47;
$text-white: #f2f2f2;
$text-light:#b1b1b1;

//Border Color
$borderColor: #ececec;