@import "table-of-contents";

/* Google Web Fonts */
@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700|Roboto:300,400,500');

/* Import Styles */
@import "common/helper";
@import "common/reset";
@import "common/theme";
@import "common/general";
@import "common/mixins";
@import "components/page-header";
@import "components/sidebar";
@import "components/pagination";

/* =====================
    Header Styles
======================= */
/* Header Top */
.header-top{
    background: #252B33;
    padding: 15px 0;
    .top-left{
        ul{
            margin: 2px 0 0 0;
            li{
                margin-right: 30px;
                display: inline-block;
                color: #b7b7b7;
                font-weight: 300;
                font-size: 15px;
                &:last-child{
                    margin-right: 0;
                }
                span{
                    color: $pcolor;
                    display: inline-block;
                    padding-right: 8px;
                }
            }
        }
    }
    .top-right{
        text-align: right;
        ul{
            margin: 0;
            display: inline-block;
            li{
                display: inline-block;
                a{
                    display: block;
                    color: #b7b7b7;
                    background: #424242;
                    font-size: 12px;
                    padding: 4px 10px;
                    border-radius: 3px;
                    &:hover{
                        background: $pcolor;
                        color: #fff;
                    }
                }
            }
        }
    }
}

/* Main Menu */
.main-menu{
    padding: 20px 0;
    transition: $transition;
    .navbar{
        justify-content: space-between;
        padding: 0;
        .navbar-toggler{
            &:focus{
                outline: none;
            }
        }
    }
}
.navbar .navbar-collapse{
    justify-content: flex-end;
    ul{
        li{
            margin-right: 20px;
            position: relative;
            &:last-child{
                margin-right: 0;
            }
            a{
                color: inherit;
                font-weight: 500;
                font-family: $hind;
                text-transform: uppercase;
                &:hover{
                    color: $pcolor;
                }
                &.dropdown-toggle{
                    &:after{
                        content: none;
                    }
                }
            }
            &.active{
                a{
                    color: $pcolor;
                }
                .dropdown-menu{
                    a{
                        color: #333;
                    }
                }
            }
            &.dropdown{
                .dropdown-menu{
                    top: 60px;
                    display: block;
                    visibility: hidden;
                    opacity: 0;
                    transition: $transition;
                    padding: 15px 0;
                    min-width: 12rem;
                    border-radius: 0;
                    border: 0 none;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                    &:before{
                        position: absolute;
                        content: "";
                        left: 8px;
                        top: -9px;
                        border-bottom: 9px solid #ececec;
                        border-right: 8px solid transparent;
                        border-left: 8px solid transparent;
                    }
                    a{
                        padding: 5px 20px;
                        font-weight: 400;
                        text-transform: capitalize;
                        &:hover{
                            background: none;
                        }
                    }
                }
                &:hover{
                    .dropdown-menu{
                        visibility: visible;
                        opacity: 1;
                        top: 40px;
                    }
                    a.nav-link{
                        color: $pcolor !important;
                    }
                }
            }
        }
    }
}
.navbar .collapse.show{
    z-index: 10;
    background: #fff;
    max-height: 400px;
    overflow: scroll;
    .navbar-nav{
        padding: 15px;
    }
}
//Full Screen Search
.nav-search-wrap{
    margin-right: 0 !important;
}
.search-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 121;
    background-color: rgba(255,255,255,0.95);
}
.search-wrap .search-inner {
    position: relative;
    width: 100%;
    height: 100%;
}
.search-wrap .search-cell {
    position: absolute;
    top: 20%;
    width: 100%;
}
.search-wrap .search-field-holder {
    width: 50%;
    margin: auto;
    position: relative;
    animation: slideInUp .3s;
}
.search-wrap .form-control {
    height: 66px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #eaeaea;
    font-size: 26px;
    margin-bottom: 0;
    color: #424242;
    position: relative;
    box-shadow: none;
    border-radius: 0;
}
.search-wrap input.form-control,
  .search-wrap input.form-control:focus {
    background-color: #fff;
}
@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
input.main-search-input::-webkit-input-placeholder {
    color: #424242;
}

input.main-search-input:-moz-placeholder {
    color: #424242;
    opacity: 1;
}

input.main-search-input::-moz-placeholder {
    color: #424242;
    opacity: 1;
}

input.main-search-input:-ms-input-placeholder {
    color: #424242;
}
#search-close {
    position: absolute;
    top: 17px;
    right: 0px;
    font-size: 30px;
    color: #424242;
    cursor: pointer;
}

//Sticky Menu Styles
.is-sticky .main-menu{
    background: #fff;
    z-index: 10 !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.13);
    padding: 20px 0;
}

/* =====================
    Hero Area Styles
======================= */
#particles{
    width: 100%;
    min-height: 780px;
    background-color: rgba(255,255,255,0);
    background-image: url('images/bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.hero-area{
    position: relative;
    &:before{
        position: absolute;
        content: "";
        width: 100%;
        left: 0;
        height: 100%;
        top: 0;
        background: rgba(0,0,0,0.02);
    }
    .hero-area-content{
        position: absolute;
        width: 100%;
        left: 0;
        top: 40%;
        transform: translateY(-50%);
        text-align: center;
        h1{
            width: 750px;
            margin: 0 auto;
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            margin-bottom: 30px;
            span{
                color: $pcolor;
            }
        }
        p{
            width: 650px;
            margin: 0 auto;
        }
        form{
            width: 500px;
            margin: 50px auto 0 auto;
            position: relative;
            input{
                width: 100%;
                height: 60px;
                border-radius: 30px;
                border: 0 none;
                background: #fff;
                box-shadow: 0 3px 15px rgba(0,0,0,0.15);
                padding: 0 30px;
            }
            button{
                position: absolute;
                background: $pcolor;
                height:52px;
                width: 52px;
                top: 4px;
                right: 7px;
                border: 0 none;
                color: #fff;
                border-radius: 30px;
                cursor: pointer;
                padding-top: 2px;
                transition: $transition;
                &:hover{
                    background: $scolor;
                }
            }
        }
    }
}
.service-box-wrapper{
    position: relative;
}
.services-boxes{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    transform: translateY(-50%);
    padding-left: 15px;
    padding-right: 15px;
    .box-single{
        box-shadow: 5px 5px 10px rgba(0,0,0,.10);
        background:#fff;
        padding: 30px;
        text-align: center;
        span.icon{
            font-size: 55px;
        }
        h3{
            font-size: 20px;
            margin: 20px 0;
        }
        ul{
            margin: 0;
            li{
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                font-weight: 300;
                padding-bottom: 8px;
                &:last-child{
                    padding-bottom: 0;
                }
                span{
                    &:last-child{
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

/* ==========================
    Service Area Styles
============================ */
.services{
    padding: 261px 0 104px 0;
    .service-image{
        figure{
            position: relative;
            figcaption{
                position: absolute;
                width: 100%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                a{
                    display:inline-block;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background:$pcolor;
                    color: #fff;
                    font-size: 25px;
                    padding-top: 20px;
                    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
                    &:hover{
                        background: $scolor;
                        color: #fff;
                    }
                }
            }
        }
    }
    .service-desc{
        h2{
            font-size: 32px;
            font-weight: 700;
            span{
                color: $scolor;
            }
        }
    }
}

/* ==========================
    How It Works - Styles
============================ */
.hiw-process{
    background: url('images/image2.jpg');
    background-size:52%;
    background-repeat: no-repeat;
    position: relative;
    &:before{
        position: absolute;
        content: "";
        height: 100%;
        width: 50%;
        right: 0;
        top: 0;
        background: #f5f5f5;
    }
}
.process-content{
    padding: 146px 0 150px;
    position: relative;
    z-index: 1;
    .content-right{
        padding: 0 30px;
    }
    h2{
        margin: 0 0 30px 0;
        font-size: 40px;
        span{
            color: $pcolor;
        }
    }
    ul{
        margin: 50px 0 0 0;
        li{
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            &:last-child{
                margin-bottom: 0;
            }
            span.icon{
                margin-right: 20px;
                width: 45px;
                height: 45px;
                border: 1px solid #dddbdb;
                color: $pcolor;
                font-size: 18px;
                text-align: center;
                padding-top: 12px;
                border-radius: 50%;
            }
        }
    }
}


/* ==========================
    Events - Styles
============================ */
.events{
    padding-bottom: 90px;
    background: url('images/image3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    &:before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top:0;
        background: rgba(255,255,255,0.98);
    }
    .single-event{
        padding: 40px 0;
        border-radius: 3px;
        margin-bottom: 30px;
        background: #fff !important;
        box-shadow: 0 2px 10px rgba(0,0,0,0.13);
        &:hover{
            color: $scolor;
        }
    }
    .a-date{
        display: table;
        height: 100%;
        padding-left: 30px;
        p{
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            font-size: 22px;
            font-weight: 400;
            line-height: 32px;
            color: $pcolor;
            span{
                display: block;
                font-size: 18px;
                color: $dark-bg;
            }
        }
    }
    .a-desc{
        display: table;
        height: 100%;
        h3{
            a{
                color: $pcolor;
                &:hover{
                    color: $scolor;
                }
            }
        }
        p{
            margin: 0;
        }
    }
    .a-icon{
        display: table;
        height: 100%;
        width:100%;
        text-align: center;
        span{
            display: table-cell;
            vertical-align: middle;
            font-size: 50px;
            transition: $transition;
        }
    }
    .pagination{
        margin-top: 50px;
        padding-bottom: 30px;
    }
}

/* ==========================
    Call To Action - Styles
============================ */
.cta{
    background: url('images/cta-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 94px 0 100px;
    &:before{
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: transparentize($pcolor, 0.2);
    }
}
.cta-contents{
    text-align: center;
    p{
        width: 650px;
        margin: 0 auto;
        color:$text-white;
        font-weight: 300;
    }
    h2{
        color: #fff;
        margin: 0 0 15px 0;
        font-size: 36px;
    }
    a{
        color: #fff;
        background: $dark-bg;
        display: inline-flex;
        align-items: center;
        padding: 10px 35px;
        margin-top: 30px;
        &:hover{
            color: $pcolor;
            background: #222;
        }
        span{
            display: inline-block;
            padding-left: 10px;
            color: $pcolor;
        }
    }
}

/* ==========================
    Project Flow - Styles
============================ */
.project-flow{
    padding-bottom: 60px;
    .flow-process{
        .flow-single{
            margin-bottom: 60px;
            .icon{
                height: 150px;
                text-align: center;
                position: relative;
                span{
                    font-size: 30px;
                    position: absolute;
                    left: 50%;
                    margin-left: -15px;
                    top: 0;
                }
                &.icon-down{
                    span{
                        bottom: 0;
                        top: auto;
                    }
                }
            }
            .divider{
                position: relative;
                margin: 30px 0;
                text-align: center;
                line-height: 0;
                span{
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    border: 3px solid #ebedf2;
                    background: #ebedf2;
                    position: relative;
                    &:before{
                        position: absolute;
                        content: "";
                        width: 2px;
                        height: 140px;
                        background: #ebedf2;
                        left: 50%;
                        bottom: 0;
                        margin-left: -1px;
                        z-index: -1;
                        transition: 0.6s ease;
                    }
                    &:after{
                        position: absolute;
                        content: "";
                        width: 8px;
                        background: #ebedf2;
                        left: 50%;
                        bottom: 140px;
                        transform: translateX(-50%);
                        z-index: -1;
                        height: 8px;
                        border-radius: 50%;
                        transition: 0.6s ease;
                    }
                }
                &.divider-down{
                    span{
                        &:before{
                            bottom: auto;
                            top: 0;
                        }
                        &:after{
                            bottom: auto;
                            top: 140px;
                        }
                    }
                }
            }
            .desc{
                text-align: center;
            }
            &:hover{
                .divider{
                    span{
                        &:before{
                            height: 0;
                        }
                        &:after{
                            bottom: 0;
                        }
                    }
                    &.divider-down{
                        span{
                            &:after{
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================
    Projects - Styles
============================ */
.projects{
    .container-fluid{
        padding-right: 0;
        padding-left: 0;
        margin-left: -15px;
        margin-right: -15px;
    }
    .section-title{
        margin-bottom: 30px;
    }
    .project-left{
        padding: 65px 30px 65px 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .project-desc{
        p{
            margin-bottom: 0;
        }
        a.btns{
            margin-top: 25px;
        }
    }
    .project-grid{
        padding-right: 0;
        .project-single{
            float: left;
            width: 33.33%;
            figure{
                margin-bottom: 0;
                position: relative;
                overflow: hidden;
                img{
                    transition: 0.5s ease;
                }
                figcaption{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: $pcolor;
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.5s ease;
                    div{
                        position: absolute;
                        width: 100%;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        text-align: center;
                        h3{
                            font-size: 22px;
                            margin: 0 0 5px 0;
                            a{
                                color:#fff;
                                &:hover{
                                    color: #333;
                                }
                            }
                        }
                        span{
                            color: #e7e7e7;
                        }
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.3);
                    }
                    figcaption{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

/* ==========================
    Team - Styles
============================ */
.team-area{
    .team-single{
        figure{
            margin: 0;
            position: relative;
            overflow: hidden;
            figcaption{
                position: absolute;
                left: 0;
                bottom: -98%;
                width: 100%;
                height: 100%;
                transition: 0.5s ease;
                background: $pcolor;
                text-align: center;
                padding: 0 15px;
                div{
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                h3{
                    color: #fff;
                    font-size: 22px;
                    margin: 0 0 5px 0;
                }
                span{
                    display: block;
                    font-weight: 300;
                    color: #eee;
                }
                ul{
                    margin-top: 30px;
                    li{
                        display: inline-block;
                        margin-right: 15px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            display: block;
                            background: #fff;
                            padding: 8px;
                            border-radius: 3px;
                            &:hover{
                                background: $scolor;
                                span{
                                    color: #fff;
                                }
                            }
                            span{
                                color: $pcolor;
                            }
                        }
                    }
                }
            }
            &:hover{
                figcaption{
                    bottom: 0;
                }
            }
        }
    }
}

/* ==========================
    Blog - Styles
============================ */
.blog{
    padding-bottom: 90px;
    .blog-single{
        margin-bottom: 30px;
        figure{
            position: relative;
            margin: 0;
            overflow: hidden;
            img{
                transition: $transition;
            }
            figcaption{
                position: absolute;
                width: 120px;
                height: 100%;
                right: 0;
                top: 0;
                background: transparentize($pcolor, 0.15);
                transition: $transition;
                ul{
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    li{
                        color: #fff;
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        span{
                            display: inline-block;
                            padding-right: 5px;
                        }
                        a{
                            display: inline-block;
                            border: 1px  solid #eee;
                            padding: 5px 10px;
                            color: #fff;
                            font-size: 14px;
                            border-radius: 3px;
                            &:hover{
                                background: $dark-bg;
                                border-color: $dark-bg;
                            }
                        }
                    }
                }
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
                figcaption{
                    width: 100%;
                    ul li a{
                        padding: 10px 30px;
                    }
                }
            }
        }
        .blog-details{
            background: #fff;
            padding: 30px;
            h3{
                margin: 0 0 15px 0;
                font-size: 25px;
                a{
                    color: inherit;
                    &:hover{
                        color: $pcolor;
                    }
                }
            }
            p{
                margin: 0;
            }
        }
    }
}

/* ==========================
    Partners - Styles
============================ */
.partners{
    padding: 60px 0;
}

/* =====================
    Footer Styles
======================= */
footer{
    padding: 80px 0 0 0;
    position: relative;
    background: url('images/image3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.98);
    }
}
.widget-area{
    margin-bottom: 40px;
    .widget-head{
        margin-bottom: 35px;
        h2{
            font-size: 26px;
            color: #fff;
            margin: 0;
            font-weight: 400;
            display: flex;
            align-items: center;
            span{
                display: inline-block;
                margin-right: 15px;
                font-size: 20px;
                color: $pcolor;
                transform: rotate(180deg);
            }
        }
    }
}
.widget-contents{
    color: $text-light;
    p{
        color: $text-light;
    }
    ul.footer-address{
        margin: 30px 0 0 0;
        li{
            margin-bottom: 5px;
            color: $text-light;
            &:last-child{
                margin-bottom: 0;
            }
            span{
                color: $pcolor;
                display: inline-block;
                margin-right: 15px;
                margin-bottom: 15px;
            }
        }
    }
    ul.footer-social{
        margin: 30px 0 0 0;
        li{
            display: inline-block;
            margin-right: 5px;
            &:last-child{
                margin-right: 0;
            }
            a{
                display: block;
                width: 35px;
                height: 35px;
                background: $pcolor;
                color: #fff;
                text-align: center;
                padding-top: 7px;
                border-radius: 3px;
                &:hover{
                    background: $scolor;
                }
            }
        }
    }
}
.latest-post-widget{
    .lp-single{
        display: flex;
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
        .lp-desc{
            margin-left: 15px;
            flex: 2;
            h3{
                margin: 0 0 10px 0;
                font-size: 18px;
                a{
                    color: $text-light;
                    &:hover{
                        color: $pcolor;
                    }
                }
            }
            span{
                display: block;
                color: $text-light;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
}
.links{
    ul{
        li{
            border-bottom: 1px solid #0D0D0D;
            a{
                color: $text-light;
                display: block;
                padding: 15px 0;
                &:hover{
                    padding-left: 10px;
                    color: $pcolor;
                }
                span{
                    display: inline-block;
                    padding-right: 5px;
                }
            }
            &:first-child{
                a{
                    padding-top: 0;
                }
            }
        }
    }
}
.sibscribe-widget{
    .form-group{
        margin: 0;
        .input, .mail{
            margin-bottom: 20px;
        }
        .input, .mail{
            position: relative;
            span{
                position: absolute;
                color: $pcolor;
                left: 15px;
                top: 24px;
            }
        }
        input{
            height: 70px;
            background: #0D0D0D;
            color: #fff;
            width: 100%;
            padding: 0 15px 0 45px;
            border-radius: 3px;
        }
        button{
            border: 0 none;
            background: $pcolor;
            color: #fff;
            padding: 10px 35px;
            border-radius: 3px;
            cursor: pointer;
            transition: $transition;
            &:hover{
                background: $scolor;
            }
        }
    }
}

//Footer Bottom
.footer-bottom{
    margin-top: 40px;
    border-top: 1px solid #0D0D0D;
    text-align: center;
    padding: 30px 0;
    p{
        margin: 0;
        a{
            color: $pcolor;
        }
    }
}



/* ==============================
    Research Page Styles
=============================== */
.research-contents{
    padding: 114px 0 120px;
}
.research-header{
    margin-bottom: 100px;
    h2{
        position: relative;
        margin: 0;
        padding: 0 0 25px 0;
        &:before{
            position: absolute;
            content:"";
            width: 100px;
            height: 3px;
            left: 0;
            bottom: 0;
            background: $pcolor;
        }
    }
    p{
        margin: 0;
    }

}
.research-keynotes{
    margin-bottom: 100px;
    .box{
        background: #f4f4f4;
        padding: 30px 15px;
        text-align: center;
        span{
            font-size: 45px;
            color: $pcolor;
        }
        h3{
            margin: 20px 0 10px 0;
        }
        p{
            margin: 0;
        }
    }
}
.research-features{
    h2{
        margin: 0 0 30px 0;
    }
    ul{
        margin: 0;
        li{
            margin:0 0 15px 0;
            display: flex;
            align-items: center;
            span{
                margin-right: 15px;
            }
            &:last-child{
                margin-top: 30px;
                margin-bottom: 0;
            }
        }
    }
}

/* ==============================
    Publications Styles
=============================== */
.publications{
    .publication-single{
        margin-bottom: 100px;
        .pbc-desc{
            h3{
                margin: 0 0 10px 0;
                font-weight: 700;
            }
            span.sub-title{
                color: $text-light;
                display: block;
                margin-bottom: 15px;
                font-weight: 300;
            }
            .author-name{
                a{
                    color: $pcolor;
                    &:hover{
                        color: $scolor;
                    }
                }
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                li{
                    color: $text-color;
                    font-weight: 300;
                    a{
                        color: $pcolor;
                        font-weight: 300;
                        &:hover{
                            color: $scolor;
                        }
                    }
                    & + li{
                        position: relative;
                        margin-left: 25px;
                        &:before{
                            position: absolute;
                            content: "/";
                            right: -15px;
                            top: 0;
                            color: $text-light;
                        }
                    }
                    &:last-child{
                        &:before{
                            content: none;
                        }
                    }
                }
            }
            a.btn{
                color: #fff;
                background: $pcolor;
                display: inline-block;
                margin-top: 15px;
                &:hover{
                    background: $scolor;
                }
            }
        }
    }
}


/* ==============================
    Projects Page
=============================== */
.project-area-title{
    padding-bottom: 40px;
}
.featured-projects{
    position: relative;
    padding: 120px 0;
    &:before{
        position: absolute;
        content: "";
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        background: #f4f4f4;
    }
    &:after{
        position: absolute;
        content: "";
        width: 50%;
        height: 100%;
        right: 0;
        top: 0;
        background:$pcolor;
        z-index: -1;
    }
    .projects-excerpts{
        .project-category{
            color: $pcolor;
            display: block;
            margin: 0 0 10px 0;
            span{
                display: inline-block;
                margin-right: 7px;
            }
        }
        h3{
            font-weight: 700;
            margin: 0 0 15px 0;
        }
        .author-name{
            a{
                color: $pcolor;
                &:hover{
                    color: $scolor;
                }
            }
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            li{
                color: $text-color;
                font-weight: 300;
                a{
                    color: $pcolor;
                    font-weight: 300;
                    &:hover{
                        color: $scolor;
                    }
                }
                & + li{
                    position: relative;
                    margin-left: 15px;
                    &:before{
                        position: absolute;
                        content: "/";
                        right: -10px;
                        top: 0;
                        color: $text-light;
                    }
                }
                &:last-child{
                    &:before{
                        content: none;
                    }
                }
            }
        }
    }
}
.project-single{
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    figure{
        margin: 0;
        img{
            border-radius: 3px 3px 0 0;
        }
        figcaption{
            padding: 30px;
            h3{
                font-weight: 700;
                font-size: 24px;
            }
            p{
                margin: 0;
            }
            a{
                font-weight: 500;
                color:$pcolor;
                display: inline-flex;
                align-items: center;
                margin-top: 15px;
                span{
                    font-size: 12px;
                    margin-left: 5px;
                }
                &:hover{
                    color: $scolor;
                }
            }
        }
    }
}
.latest-project{
    .latest-single{
        display: flex;
        min-height: 200px;
        border-radius: 3px;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
        margin-bottom: 30px;
        transition: 0.3s ease;
        &:hover{
            box-shadow: 2px 2px 20px rgba(0,0,0,0.15);
            margin-top: -5px;
            margin-bottom: 35px;
            .right h3 a{
                color: $pcolor;
            }
        }
        .left{
            text-align: center;
            background: #f4f4f4;
            padding: 30px;
            span{
                font-size: 24px;
                color: $pcolor;
            }
        }
        .right{
            background: #fff;
            padding: 30px;
            span{
                color: $text-light;
            }
            h3{
                font-weight: 700;
                margin: 10px 0 15px 0;
                a{
                    color: inherit;
                }
            }
            p{
                margin: 0;
            }
        }
    }
    .btn-wrapper{
        margin-top: 30px;
    }
}

/* ==============================
    Project Details Styles
=============================== */
.project-details{
    padding: 120px 0;
    .project-gallery{
        margin-bottom: 60px;
        position: relative;
        .gallery-single{
            figure{
                position: relative;
                border-radius: 3px;
                overflow: hidden;
                img{
                    border-radius: 3px;
                }
                figcaption{
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                    height: 100%;
                    text-align: center;
                    background: rgba(0,0,0,0.3);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.4s ease;
                    transform: scale(2);
                    a{
                        font-size: 24px;
                        color: #fff;
                        &:hover{
                            color: $pcolor;
                        }
                    }
                }
                &:hover{
                    figcaption{
                        opacity: 1;
                        visibility: visible;
                        transform: scale(1);
                    }
                }
            }
        }
        .owl-nav{
            display: flex;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            .owl-prev{
                margin-left: -40px;
                transition: $transition;
            }
            .owl-next{
                margin-right: -40px;
                transition: $transition;
            }
            .owl-prev, .owl-next{
                &:hover{
                    color: $pcolor;
                }
            }
        }
    }
    .contents-wrapper{
        .project-desc{
            .social-share{
                margin-top: 30px;
            }
        }
        .project-sidebar{
            ul{
                margin-bottom: 20px;
                li{
                    color: #504b47;
                    font-weight: 300;
                    margin-bottom: 7px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    span{
                        font-weight: 400;
                        min-width: 100px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
.related-projects{
    .section--title{
        span{
            color: $pcolor;
        }
        margin: 0 0 30px 0;
    }
}
/* ==============================
    Blog Styles
=============================== */
.blog-wrapper{
    padding: 111px 0 111px 0;
    .blog-single{
        margin-bottom: 60px;
        padding-bottom: 60px;
        border-bottom: 1px solid #f1f1f1;
        .blog-excerpt{
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 30px 0;
            li{
                margin-right: 25px;
                position: relative;
                &:before{
                    position: absolute;
                    content: "|";
                    right: -15px;
                    top: 0;
                    color: $text-light;
                }
                &:last-child{
                    margin-right: 0;
                    &::before{
                        content: none;
                    }
                }
                a{
                    color:$pcolor;
                }
            }
        }
        figure{
            margin: 0;
            overflow: hidden;
            &:hover{
                a img{
                    transform: scale(1.1);
                }
            }
            a img{
                transition: 0.5s ease;
            }
        }
        .p-excerpt{
            margin: 30px 0;
            &::first-letter{
                color:$pcolor;
                font-weight: 700;
                float: left;
                font-size: 2.935em;
                line-height: .9;
                padding-top: 8px;
                padding-right: 8px;
            }
        }
        .read-more{
            text-align: center;
            a{
                display: inline-block;
            }
        }
    }
}
//Blog details
.blog-details{
    .blog-thumb{
        position: relative;
        margin-bottom: 0;
        figcaption{
            position: absolute;
            left: 0;
            top: 15px;
            span.blog-badge{
                display: block;
                width: 80px;
                height: 60px;
                background: $pcolor;
                text-align: center;
                color: #fff;
                padding-top: 7px;
                span{
                    display: block;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
    .blog-contents{
        .blog-title{
            margin: 30px 0 15px 0;
        }
        blockquote{
            border: 15px solid #f5f5f5;
            padding: 20px;
            font-style: italic;
            margin: 30px 0;
            span{
                color: $pcolor;
                display: inline-block;
                padding-right: 5px;
            }
        }
        .post-bottom-excerpt{
            background: #f5f5f5;
            margin-top: 30px;
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                align-items: center;
                padding: 10px;
                li{
                    font-size: 14px;
                    &:first-child{
                        margin-right: auto;
                    }
                    &.post-author{
                        color: $text-color;
                        span{
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            background: #fff;
                            text-align: center;
                            padding-top: 7px;
                            margin-right: 5px;
                        }
                        a{
                            color: $pcolor;
                        }
                    }
                    &.post-comment, &.post-likes{
                        margin-right: 10px;
                        a{
                            display: block;
                            background: #fff;
                            padding: 5px 10px;
                            color: $text-light;
                            &:hover{
                                color: $pcolor;
                            }
                            span{
                                display: inline-block;
                                padding-right: 5px;
                            }
                        }
                    }
                    &.post-tags{
                        background: #fff;
                        padding: 5px 10px;
                        color: $text-light;
                        a{
                            color: $text-light;
                            &:hover{
                                color: $pcolor;
                            }
                        }
                        span{
                            display: inline-block;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
.post-comments{
    margin-top: 90px;
    .section--title{
        span{
            color: $pcolor;
        }
        margin: 0 0 30px 0;
    }
    .comments-list{
        .comment-single{
            .comment-details{
                position: relative;
                display: flex;
                justify-content: flex-start;
                border-bottom: 1px solid $borderColor;
                margin-bottom: 30px;
                padding-bottom: 30px;
                .author-avater{
                    width: 100px;
                    height: 100px;
                    margin-right: 30px;
                }
                .author-comment{
                    h4{
                        font-size: 18px;
                        margin: 0;
                    }
                    span{
                        font-size: 12px;
                        color: $text-color;
                    }
                    p{
                        margin: 0;
                    }
                }
                .reply-btn{
                    position: absolute;
                    padding: 3px 12px;
                    background: #f5f5f5;
                    color: $text-light;
                    top: 0;
                    right: 0;
                    &:hover{
                        background: $pcolor;
                        color: #fff;
                    }
                }
            }
            .comment-replys{
                margin-left: 90px;
            }
        }
    }
}
.comment-form{
    margin-top: 80px;
    .section--title{
        span{
            color: $pcolor;
        }
        margin: 0 0 30px 0;
    }
    form{
        .form-group{
            margin-bottom: 0;
        }
        input{
            width: 100%;
            height: 45px;
            border: 1px solid #e1e1e1;
            margin-bottom: 30px;
            padding: 0 15px;
        }
        textarea{
            height: 120px;
            width: 100%;
            border: 1px solid #e1e1e1;
            padding: 15px;
            margin-bottom: 30px;
        }
        button{
            background: $pcolor;
            color: #fff;
            border: 0 none;
            padding: 10px 35px;
            cursor: pointer;
        }
    }
}

/* ==============================
    Careers Styles
=============================== */
.careers-contents{
    padding: 120px 0 115px;
    .job-areas{
        margin-top: 60px;
        h3{
            font-weight: 700;
            margin: 0 0 20px 0;
        }
        p{
            span{
                font-weight: 500;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .job-benefits{
        margin-top: 60px;
        h3{
            font-weight: 700;
            margin: 0 0 20px 0;
        }
        ul{
            margin: 0;
            li{
                margin-bottom: 15px;
                color: $text-color;
                font-weight: 300;
                &:last-child{
                    margin-bottom: 0;
                }
                span{
                    margin-right: 10px;
                    display: inline-block;
                    color: $pcolor;
                }
            }
        }
    }
}
.open-positions{
    padding: 116px 0 90px 0;
    border-top: 1px solid $borderColor;
    .job-single{
        border: 1px solid $borderColor;
        border-radius: 3px;
        margin-bottom: 30px;
        padding: 30px;
        position: relative;
        transition: 0.3s ease;
        &:hover{
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        }
        h4{
            font-size: 20px;
        }
        span{
            display: block;
            color: $text-light;
        }
        a{
            display: inline-block;
            margin-top: 10px;
            font-weight: 500;
            color: $pcolor;
        }
        a.job-ft, a.job-pt{
            font-size: 12px;
            margin-top: 0;
            font-weight: 400;
            position: absolute;
            padding: 0 10px;
            line-height: 26px;
            color: #fff;
            right: 15px;
            top: 15px;
            border-radius: 3px;
        }
        a.job-ft{
            background: $pcolor;
        }
        a.job-pt{
            background: $scolor;
        }
    }
}

/* ==============================
    Career Details Styles
=============================== */
.career-details{
    padding: 111px 0 120px;
    .cd-head{
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $borderColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        span{
            color: $text-color;
            font-weight: 300;
        }
    }
    .cd-contents{
        p{
            margin: 0;
        }
        h3{
            margin: 40px 0 20px 0;
        }
        ul{
            li{
                margin-bottom: 15px;
                color: $text-color;
                font-weight: 300;
                &:last-child{
                    margin-bottom: 0;
                }
                span{
                    display: inline-block;
                    margin-right: 10px;
                    color: $pcolor;
                }
            }
        }
    }
    .cd-about{
        h3{
            margin: 40px 0 20px 0;
        }
    }
    .apply-btn{
        margin-top: 60px;
        text-align: center;
    }
}
/* ==============================
    About Page Styles
=============================== */
.about-contents{
    padding: 120px 0;
}

/* ==============================
    Contact Page Styles
=============================== */
.contact-wrapper{
    padding: 120px 0;
    .contact-boxes{
        margin-bottom: 60px;
        .box{
            text-align: center;
            span{
                font-size: 40px;
                color: $pcolor;
                display: block;
            }
            h4{
                font-size: 18px;
                margin: 20px 0 10px 0;
            }
        }
    }
    .contact-form{
        .form-group{
            margin: 0;
            input, textarea{
                width: 100%;
                border:1px solid $borderColor;
                margin-bottom: 30px;
                height: 45px;
                padding: 0 15px;
                border-radius: 3px;
                transition: $transition;
                &:focus{
                    border-color: $pcolor;
                }
            }
            textarea{
                min-height: 150px;
                padding: 15px;
            }
            button{
                border: 0 none;
                background: $pcolor;
                color: #fff;
                font-weight: 500;
                padding: 15px 35px;
                border-radius: 3px;
                cursor: pointer;
                transition: $transition;
                &:hover{
                    background: $scolor;
                }
            }
        }
    }
}
.contact-map{
    #gmap{
        width: 100%;
        height: 400px;
    }
}




















/***************************************
    RESPONSIVE CSS GOES HERE
****************************************/
@import "responsive";