/*****************************
	RESPONSIVE
*****************************/
@import "common/theme";
/*this is responsive scss*/
$tablet: 768px;
$laptop: 1070px;
$ipad: 992px;

/* 992 TO 1199 */
@media (min-width:$ipad ) and (max-width: 1199px) {
	.services-boxes .box-single ul li{
		text-align: left;
	}
	.process-content{
		padding: 60px 0;
	}
	.hiw-process{
		background-size: 60%;
	}
}

/* 991 */
@media (max-width: $ipad - 1) {
	.navbar .navbar-collapse{
		ul{
			li{
				&.dropdown{
					.dropdown-menu{
						top: 100%;
						display: none;
						visibility: visible;
						opacity: 1;
						&.show{
							display: block;
						}
						&:before{
							content: none;
						}
					}
					&:hover{
						.dropdown-menu{
							visibility: visible;
							opacity: 1;
						}
					}
				}
			}
		}
	}
	.service-box-wrapper{
		padding: 120px 0 90px 0;
	}
	.services-boxes{
		position: relative;
		transform: inherit;
		.box-single{
			margin-bottom: 30px;
			box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
		}
	}
	.services{
		padding-top: 120px;
		border-top: 1px solid $borderColor;
	}
	.service-image{
		margin-bottom: 30px;
		figure{
			img{
				width: 100%;
			}
		}
	}
	.hiw-process:before{
		width: 100%;
	}
	.events .single-event{
		text-align: center;
	}
	.events .a-date{
		width: 100%;
		padding-left: 0;
	}
	.events .a-date, .events .a-desc{
		margin-bottom: 30px;
	}
	.blog-sidebar{
		margin-top: 80px;
	}
	.projects-excerpts{
		margin-bottom: 60px;
	}
	.projects .project-single{
		margin-bottom: 0;
	}
	.project-single{
		margin-bottom: 30px;
	}
	.research-keynotes .box{
		margin-bottom: 30px;
	}
	.research-features ul{
		margin-bottom: 30px;
	}
	.project-sidebar a.btns{
		padding: 10px 22px;
	}
	.featured-projects:before{
		width: 100%;
	}
	.publications .publication-single .pbc-desc h3{
		margin: 30px 0 10px 0;
	}
	.publications .publication-single{
		margin-bottom: 60px;
	}
	.publication-single img{
		margin-top: 30px;
	}
	.research-features img{
		width: 100%;
	}
}

/* 768 TO 992*/
@media (min-width: $tablet) and (max-width: $ipad - 1px) {
	#particles{
		min-height: 600px;
	}
	.hero-area .hero-area-content{
		top: 50%;
	}
}

/* 767 */
@media  screen and (max-width: $tablet - 1) {
	#particles{
		min-height: 500px;
	}
	.hero-area .hero-area-content{
		top: 50%;
	}
	.hero-area .hero-area-content h1,
	.hero-area .hero-area-content p,
	.section-title p,
	.cta-contents p{
		width: 100%;
	}

	.events .single-event{
		text-align: center;
	}
	.events .a-date{
		width: 100%;
		padding-left: 0;
	}
	.events .a-date, .events .a-desc{
		margin-bottom: 30px;
	}
	.hero-area .hero-area-content form{
		width: 90%;
	}
	.projects .project-grid .project-single{
		width: 50%;
	}
	.header-top .top-left{
		text-align: center;
	}
	.header-top .top-right{
		text-align: center;
		margin-top: 15px;
	}
	.research-features h2{
		margin: 30px 0 15px 0;
	}
	.research-features ul{
		margin-bottom: 0;
	}
	.process-content{
		padding: 116px 0 120px;
	}
	.blog-details .blog-contents .post-bottom-excerpt ul{
		li{
			width: 100%;
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.blog-details .blog-contents .post-bottom-excerpt ul li.post-comment, .blog-details .blog-contents .post-bottom-excerpt ul li.post-likes{
		margin-right: 0;
	}
	.contact-wrapper .contact-boxes .box{
		margin-bottom: 30px;
	}
	.project-details .project-gallery .owl-nav{
		display: none;
	}
	.research-features img{
		margin-top: 30px;
	}
}

/* 320 TO 480 */
@media  (min-width: 320px) and (max-width: 480px) {
	.projects .project-grid .project-single{
		width: 100%;
	}
	.post-comments .comments-list .comment-single .comment-replys{
		margin-left: 40px;
	}
}