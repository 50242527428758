@import "theme";
//Font Family
$roboto: 'Roboto', sans-serif;
$hind: 'Hind', sans-serif;

//Transition
$transition: 0.3s ease;

//Color Classes
.pcolor{
    color: $pcolor;
}
.scolor{
    color: $scolor;
}
.color1{
    color: $color1;
}
.color2{
    color: $color2;
}

//BG Color Classes
.pcolor-bg{
    background: $pcolor;
}
.scolor-bg{
    background: $scolor;
}
.color1-bg{
    background: $color1;
}
.color2-bg{
    background: $color2;
}

.section-bg{
    background: $section-bg;
}

//Section Padding
.section-padding{
    padding: 116px 0 120px 0;
}

//Section Title Style
.section-title{
    margin-bottom: 70px;
    text-align: center;
    h2{
        margin: 0 0 15px 0;
        font-size: 40px;
        font-weight: 700;
        span{
            color: $pcolor;
        }
    }
    p{
        margin: 0 auto;
        width: 650px;
        text-align: center;
    }
    &.title-left{
        text-align: left;
        p{
            text-align: left;
            width: 100%;
        }
    }
}

//Buttons
.btns{
    display: inline-block;
    padding: 10px 35px;
    background: $pcolor;
    color: #fff;
    font-weight: 400;
    border-radius: 3px;
    margin-top: 10px;
    &:hover{
        background: $scolor;
        color: #fff;
    }
}

//Social Share
.social-share{
    display: flex;
    flex-wrap: wrap;
    li{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
        a{
            display: block;
            padding: 12px;
            border: 1px solid $pcolor;
            color: $pcolor;
            line-height: 0;
            &:hover{
                background: $pcolor;
                color: #fff;
            }
        }
    }
}

//Pagination
.pagination{
    text-align: center;
    ul{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
        li{
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
            a{
                padding: 12px 15px;
                border: 1px solid $borderColor;
                color: $text-light;
                display: block;
                &:hover{
                    color: $pcolor;
                    border-color: $pcolor;
                }
            }
            &.active{
                a{
                    color: #fff;
                    border-color: $pcolor;
                    background: $pcolor;
                }
            }
        }
    }
}

/* -------- Pre Loader -------- */
.preloader{
	width: 100%;
	height: 100%;
	background: #fff;
	position: fixed;
	left:0;
	top: 0;
	z-index: 99999;
}
#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.7em;
  margin-left: -2.7em;
  width: 5.4em;
  height: 5.4em;
  background-color: #fff;
	z-index: 100;
}
#hill {
  position: absolute;
  width: 7.1em;
  height: 7.1em;
  top: 1.7em;
  left: 1.7em;
  background-color: transparent;
  border-left: .25em solid $pcolor;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
}
#hill:after {
  content: '';
  position: absolute;
  width: 7.1em;
  height: 7.1em;
  left: 0;
  background-color: #fff;
}
#box {
  position: absolute;
  left: 0;
  bottom: -.1em;
  width: 1em;
  height: 1em;
  background-color: transparent;
  border: .25em solid $pcolor;
  border-radius: 15%;
  -ms-transform: translate(0, -1em) rotate(-45deg);
      transform: translate(0, -1em) rotate(-45deg);
  animation: push 2.5s cubic-bezier(.79, 0, .47, .97) infinite;
}
@keyframes push {
  0% {
    transform: translate(0, -1em) rotate(-45deg);
  }
  5% {
    transform: translate(0, -1em) rotate(-50deg);
  }
  20% {
    transform: translate(1em, -2em) rotate(47deg);
  }
  25% {
    transform: translate(1em, -2em) rotate(45deg);
  }
  30% {
    transform: translate(1em, -2em) rotate(40deg);
  }
  45% {
    transform: translate(2em, -3em) rotate(137deg);
  }
  50% {
    transform: translate(2em, -3em) rotate(135deg);
  }
  55% {
    transform: translate(2em, -3em) rotate(130deg);
  }
  70% {
    transform: translate(3em, -4em) rotate(217deg);
  }
  75% {
    transform: translate(3em, -4em) rotate(220deg);
  }
  100% {
    transform: translate(0, -1em) rotate(-225deg);
  }
}