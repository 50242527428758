@import "common/theme";

.blog-sidebar{
    .sidebar-widget{
        margin-bottom: 60px;
        &:last-child{
            margin-bottom: 0;
        }
        h3{
            margin: 0 0 30px 0;
            position: relative;
            font-weight: 700;
            font-size: 22px;
        }
        &.search-widget{
            form{
                position: relative;
                input{
                    width: 100%;
                    border: 1px solid #ddd;
                    height: 45px;
                    padding: 0 60px 0 15px;
                    transition: 0.3s ease;
                    &:focus{
                        border-color: $pcolor;
                    }
                }
                button{
                    width: 45px;
                    height: 45px;
                    background: $pcolor;
                    color: #fff;
                    border: 1px solid $pcolor;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
        &.recent-post, &.archieve-widget{
            ul{
                margin-bottom: 0;
                li{
                    padding: 10px 0 10px 25px;
                    position: relative;
                    &:first-child{
                        padding-top: 0;

                    }
                    &:before{
                        position: absolute;
                        content: "";
                        width: 10px;
                        height: 1px;
                        left: 0;
                        background: $pcolor;
                        top: 20px;
                    }
                    a{
                        color: #616161;
                        &:hover{
                            color: $pcolor;
                        }
                    }
                }
            }
        }
        &.tags-widget{
            ul{
                margin-bottom: 0;
                li{
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        display: block;
                        padding: 10px 0;
                        border-bottom: 1px solid #ddd;
                        color: #616161;
                        &:hover{
                            color: $pcolor;
                            border-color: $pcolor;
                        }
                    }
                }
            }
        }
    }
}