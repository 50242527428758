/*
Template Name: Startai - Agency & Startup HTML Template
Version: 1.0
Author: SnazzyTheme
Template URI: https://themeforest.net/user/SnazzyTheme
License URI: https://themeforest.net/user/SnazzyTheme
Tags: Startai, ai, template, bootstrap
*/

/* ------------------------------------------------------------------
[Table of contents]
--------------------
1.  General Styles
2.  Index HTML
	2.1  - Header
	2.2  - Hero Area
	2.3  - Service Area
	2.4  - How It Works
	2.5  - Events
	2.6  - Call To Action
	2.7  - Project Flow - Styles
	2.8  - Projects
	2.9  - Team - Styles
	2.10 - Blog - Styles
	2.11 - Partners - Styles
	2.12 - Footer

3.	Research Page
4.	Publications Page
5.	Projects details
6.	Project Details Styles
7.	Blog Styles
8.	Careers Styles
9.	Career Details Page
10.	Contact Page

------------------------------------------------------------------- */